import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Activity } from 'src/app/core/services/catalog/activity.data';

@Component({
  selector: 'app-todays-activity-dialog',
  templateUrl: './todays-activity-dialog.component.html',
  styleUrl: './todays-activity-dialog.component.scss'
})
export class TodaysActivityDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TodaysActivityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public activity: Activity
  ) { }

  closeDialog()
  {
    this.dialogRef.close();
  }
}
