import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { Router } from '@angular/router';
import confetti from 'canvas-confetti';
import { Activity } from 'src/app/core/services/catalog/activity.data';
import { TicksService } from 'src/app/core/services/ticks/ticks.service';
import { TodayService } from 'src/app/core/services/today/today.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SummerWheelInfoDialogComponent } from '../summer-wheel-info-dialog/summer-wheel-info-dialog.component';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { TodaysActivityDialogComponent } from '../todays-activity-dialog/todays-activity-dialog.component';
import { WonActivityDialogComponent } from '../won-activity-dialog/won-activity-dialog.component';
import { EarlierWonActivitiesComponent } from '../earlier-won-activities/earlier-won-activities.component';
import { CatalogTagService } from 'src/app/core/services/catalog/catalog-tag.service';
import { ECatalogItemType } from 'src/app/core/services/catalog/catalog-item-type.enum';
import { CatalogItem } from 'src/app/core/services/catalog/catalog-item.data';
import { WheelOfSummerService } from 'src/app/core/services/wheel-of-summer/wheel-of-summer.service';
import { CatalogActivityService } from 'src/app/core/services/catalog/catalog-activity.service';
import { WonActivityService } from 'src/app/core/services/won-activity/won-activity.service';
import { take } from 'rxjs';
import { NewWonActivity } from 'src/app/core/services/won-activity/new-won-activity.data';
import { EWonActivityType } from 'src/app/core/services/won-activity/won-activity-type.enum';

@Component({
  selector: 'app-summer-wheel-page',
  templateUrl: './summer-wheel-page.component.html',
  styleUrls: ['./summer-wheel-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummerWheelPageComponent {
  faChevronRight = faChevronRight;
  
  summerImages=["assets/images/summer-wheel/a.png", "assets/images/summer-wheel/b.png", "assets/images/summer-wheel/c.png", "assets/images/summer-wheel/d.png", "assets/images/summer-wheel/e.png"];
  randomImage = this.summerImages[Math.floor(Math.random() * this.summerImages.length)];

  // Won activities
  wonActivities = this.wonActivityService.activities;
  wonActivitiesError = this.wonActivityService.activitiesError;

  // Todays activity
  todaysActivity = computed(() => {
    return this.wonActivities().find(a => this.todayService.isToday(this.ticksService.getDate(a.created)));
  });

  // Wheel to display
  private wheels = this.wheelService.wheels;
  wheelsError = this.wheelService.wheelsError;

  private catalogActivities = this.catalogItemService.activities;
  catalogActivitiesError = this.catalogItemService.activitiesError;

  selectedWheel = computed(() => {
    let selectedWheel = this.wheels().find(wheel => this.todayService.isToday(this.ticksService.getDate(wheel.date)));

    if (!selectedWheel) {
      selectedWheel = this.wheels()[0];
    }

    selectedWheel.activities = [];
    selectedWheel.activityIds.forEach((activityId) => {

      let activity = this.catalogActivities().find(a => a.id === activityId);
      if (activity) {
        selectedWheel?.activities.push(activity);
      }
    });
    return selectedWheel;
  });

  constructor(
    private wheelService: WheelOfSummerService,
    private catalogItemService: CatalogActivityService,
    private wonActivityService: WonActivityService,
    private ticksService: TicksService,
    private todayService: TodayService,
    private router: Router,
    public bottomSheet: MatBottomSheet,
    private location: Location,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SummerWheelPageComponent>,
    private catalogTagsService: CatalogTagService
  ) { }

  listenForWheelRotation() { }

  async onWheelSpun(activity: CatalogItem) {
    
    const newWonActivity: NewWonActivity = {
      activityStr: activity.item,
      type: EWonActivityType.Summer
    };

    this.wonActivityService.winActivity(newWonActivity).pipe(
      take(1), // to complete the subscription immediately
    ).subscribe();

    await this.delay(400);

    setTimeout(this.shoot, 0);
    setTimeout(this.shoot, 100);
    setTimeout(this.shoot, 200);
    setTimeout(this.shoot, 300);
    setTimeout(this.shoot, 400);
    setTimeout(this.shoot, 500);
    setTimeout(this.shoot, 600);
    setTimeout(this.shoot, 700);
    setTimeout(this.shoot, 800);
    setTimeout(this.shoot, 900);
    setTimeout(this.shoot, 1000);

    const activityObj = JSON.parse(activity.item);
    const wonActity = activityObj as Activity;

    this.dialog.open(WonActivityDialogComponent, {
      data: wonActity,
      panelClass: 'app-won-summer-dialog',
      minHeight: '500px',
      maxHeight: '500px',
      width: '95%',
      maxWidth: '480px',
      autoFocus: false
    });  
  }

  navigateDailyActivity() {
    this.dialog.open(TodaysActivityDialogComponent, {
      data: this.todaysActivity()!.activity,
      panelClass: 'app-full-bleed-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });
  }

  navigateEarlierActivities() {
    this.dialog.open(EarlierWonActivitiesComponent, {
      data: this.wonActivities().sort((a,b) => a.created - b.created),
      panelClass: 'app-full-bleed-dialog2',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });
  }

  navigateCatalog() {
    this.catalogTagsService.selectedItemType.set(ECatalogItemType.Activity);
    this.router.navigateByUrl(`catalog/catalog`);
  }

  navigateBack(): void {
    this.location.back();
  }

  openInfoIcon(): void {
    const dialogRef = this.dialog.open(SummerWheelInfoDialogComponent, {
      panelClass: 'app-full-bleed-dialog2',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl(result);
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


  private shoot() {
    const defaults = {
      spread: 360,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ['star'],
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
    };

    confetti({
      ...defaults,
      particleCount: 40,
      scalar: 1.2,
      shapes: ['star']
    });

    confetti({
      ...defaults,
      particleCount: 10,
      scalar: 0.75,
      shapes: ['circle']
    });
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
