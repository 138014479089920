import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Activity } from 'src/app/core/services/catalog/activity.data';

@Component({
  selector: 'app-summer-wheel-info-dialog',
  templateUrl: './summer-wheel-info-dialog.component.html',
  styleUrl: './summer-wheel-info-dialog.component.scss'
})
export class SummerWheelInfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SummerWheelInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public activity: Activity
  ) { }
}
