import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-img',
  templateUrl: './product-img.component.html',
  styleUrl: './product-img.component.scss'
})
export class ProductImgComponent {
  @Input() url: string | null = '';
  @Input() text: string = '';
}
