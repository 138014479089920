import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Activity } from 'src/app/core/services/catalog/activity.data';

@Component({
  selector: 'app-earlier-won-activity',
  templateUrl: './earlier-won-activity.component.html',
  styleUrl: './earlier-won-activity.component.scss'
})
export class EarlierWonActivityComponent {

  constructor(
    public dialogRef: MatDialogRef<EarlierWonActivityComponent>,
    @Inject(MAT_DIALOG_DATA) public activity: Activity
  ) { }

  closeDialog()
  {
    this.dialogRef.close();
  }
}


