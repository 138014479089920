<mat-card id="animatedWheel" class="spin-animation" style="background-color: transparent; border:none;box-shadow:none; padding-top: 0.5rem;">
  <div class="wheel-of-fortune">
    <canvas #canvas style=" border-radius: 100%; box-shadow: 0px 0px 10px #888888; display: block;" width="320"
    height="320"></canvas>
    <div>
      <div class="spin" [ngStyle]="{'background-color': spinBackground}" (click)="canSpin ? onSpin() : false">
        @if (spinText) {
          <span></span>
        }
        @else {
          <fa-icon [ngClass]="canSpin ? 'fa-beat' : 'fa-beat-no-spin'" [icon]="faPlay"
          style="--fa-animation-duration: 1.1s; --fa-beat-scale: 1.2;" size="lg"></fa-icon>
        }
      </div>
    </div>
  </div>
</mat-card>