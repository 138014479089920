import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sector } from '../../models/sector.model';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { CatalogItem } from 'src/app/core/services/catalog/catalog-item.data';

@Component({
  selector: 'app-wheel-of-summer',
  templateUrl: './wheel-of-summer.component.html',
  styleUrls: ['./wheel-of-summer.component.scss']
})
export class WheelOfSummerComponent {
  @ViewChild('canvas', { static: true })
  canvas!: ElementRef<HTMLCanvasElement>;

  @Input() activities!: CatalogItem[];
  @Input() canSpin: boolean = true;
  @Output() wheelSpunEvent = new EventEmitter<CatalogItem>();
  @Output() rotateEvent = new EventEmitter();

  private canvasCtx!: CanvasRenderingContext2D;

  sectors: Sector[] = [
    {color: '#fe019a', activity: null,imageSrc:"../../../../assets/images/summer-wheel/04.png"},
    {color: '#fce41a', activity: null,imageSrc:"../../../../assets/images/summer-wheel/03.png"},
    {color: '#d4320e', activity: null,imageSrc:"../../../../assets/images/summer-wheel/05.png"},
    {color: '#FFA500', activity: null,imageSrc:"../../../../assets/images/summer-wheel/01.png"}
  ];

  totalSectors = this.sectors.length;
  radius = 0;
  PI = Math.PI;
  TAU = 2 * this.PI;
  arc = this.TAU / this.sectors.length;
  friction = 0.991; // 0.995=soft, 0.99=mid, 0.98=hard
  angularVelocity = 0; // Angular velocity
  angleInRadians = 0; // Angle in radians
  spinText: string | null = null;
  spinBackground = this.sectors[0].color;
  hasSpun = false;
  winningSector: Sector | null = null;

  faPlay = faPlay;

  ngOnInit(): void {
    this.startWheelAnimation();
    this.sectors[0].activity = this.activities[0];
    this.sectors[1].activity = this.activities[1];
    this.sectors[2].activity = this.activities[2];
    this.sectors[3].activity = this.activities[3];
    this.drawCanvas();
  }

  onSpin(): void {
    if (!this.angularVelocity) {
      this.hasSpun = true;
      this.angularVelocity = this.getRandom();
      this.engine(); // Start engine
    }
  }

  private drawCanvas(): void {
    const ctx = this.canvas.nativeElement.getContext('2d');
    if (ctx) {
      this.canvasCtx = ctx;
      this.radius = this.canvasCtx.canvas.width / 2;

      for (let i = 0; i < this.sectors.length; i++) {
        this.drawSector(this.sectors[i], i);
      }
      this.rotate();  // initial rotation
    }
  }

  private getRandom(): number {
    return Math.random() * (0.35 - 0.25) + 0.25;
  }

  private getIndex(): number {
    return Math.floor(this.totalSectors - this.angleInRadians / this.TAU * this.totalSectors) % this.totalSectors;
  }

  private drawSector(sector: Sector, index: number): void {
    const angle = this.arc * index;

    if (this.canvasCtx) {
      this.canvasCtx.save();

      // COLOR
      this.canvasCtx.beginPath();
      this.canvasCtx.fillStyle = sector.color;
      this.canvasCtx.moveTo(this.radius, this.radius);
      this.canvasCtx.arc(this.radius, this.radius, this.radius, angle, angle + this.arc);
      this.canvasCtx.lineTo(this.radius, this.radius);
      this.canvasCtx.fill();

      // TEXT
      this.canvasCtx.translate(this.radius, this.radius);
      this.canvasCtx.rotate(angle + this.arc / 2);
      this.canvasCtx.textAlign = 'right';
      this.canvasCtx.fillStyle = '#fff';
      this.canvasCtx.font = 'bold 25px sans-serif';
      if(index ==0){

        this.loadImage(sector.imageSrc).then((img) =>{
        
          this.canvasCtx.drawImage(
            img,
            40,
            40,
            90,
            90
          );
        }
        );

      }else if(index ==1){
        this.loadImage(sector.imageSrc).then(img =>
          this.canvasCtx.drawImage(
            img,
            190,
            40,
            90,
            90
          )
        );
      }else if(index ==2){
        this.loadImage(sector.imageSrc).then(img =>
          this.canvasCtx.drawImage(
            img,
            190,
            190,
            90,
            90
          )
        );
      }else if(index ==3){
        this.loadImage(sector.imageSrc).then(img =>
          this.canvasCtx.drawImage(
            img,
            40,
            190,
            90,
            90
          )
        );
      }
      //
      this.canvasCtx.restore();
    }
  }

  private loadImage = (url: string | null) => new Promise<HTMLImageElement>((resolve) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.src = url ? url : '';
  });

  private rotate(): void {
    const sector = this.sectors[this.getIndex()];

    if (this.canvasCtx) {
      this.canvasCtx.canvas.style.transform = `rotate(${this.angleInRadians - this.PI / 2}rad)`;
      this.spinText = !this.angularVelocity ? this.spinText : (sector.activity ? sector.activity?.name : null);
      this.spinBackground = sector.color;

      if (!this.angularVelocity) {
        this.winningSector = sector;
        if (this.hasSpun && sector.activity) {
          this.wheelSpunEvent.emit(sector.activity);
          this.spinText = '';
        }
      } else {
        this.winningSector = null;
      }

      this.rotateEvent.emit();
    }
  }



  private frame(): void {
    if (!this.angularVelocity) {
      return;
    }
    this.angularVelocity *= this.friction; // Decrement velocity by friction
    if (this.angularVelocity < 0.002) {
      this.angularVelocity = 0; // Bring to stop
    }
    this.angleInRadians += this.angularVelocity; // Update angle
    this.angleInRadians %= this.TAU; // Normalize angle
    this.rotate();
  }

  private engine(): void {
    this.frame();
    requestAnimationFrame(() => this.engine());
  }

  startWheelAnimation() {
    const myWheel = <HTMLElement>document.getElementById('animatedWheel')
    myWheel.addEventListener("click", (e) => {
      e.preventDefault();

      // https://www.youtube.com/watch?v=XGBEM8oWoCI
/*       let audio = new Audio();
      audio.src = "../../../../assets/sounds/bubble-pop.mp3";
      audio.load();
      audio.play(); */
    });
  }
}
