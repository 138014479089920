import { Component, EventEmitter, Output } from '@angular/core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-pink-back-btn',
  templateUrl: './pink-back-btn.component.html',
  styleUrl: './pink-back-btn.component.scss'
})
export class PinkBackBtnComponent {
  @Output() navigateBackEvent = new EventEmitter();

  faArrowLeft = faArrowLeft;

  navigateBack() {
    this.navigateBackEvent.emit();
  }
}
