<section class="activity-section">
  <app-pink-back-btn (navigateBackEvent)="closeDialog()"></app-pink-back-btn>

  <h1>{{ 'summer-wheel.earlier-won' | translate }}</h1>
  @for (wonActivity of wonActivities; track wonActivity.id) {
    <ul style="display: flex; flex-direction: column; gap: 1rem;">
      <li>
        <button class="won-activity-btn" (click)="navigateEarlyActivity(wonActivity)">
          <img class="reward-img" [src]="wonActivity.activity.imageStr" style="display:flex; justify-content: center; object-fit: cover; min-height: 105px; width: 100%; border-radius: 10px;    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;">
          <div class="arrow-wrapper">
            <fa-icon [icon]="faChevronRight" style="color: #fe019a; font-size: 40px;"></fa-icon>
          </div>
        </button>
      </li>
    </ul>
  }
</section>