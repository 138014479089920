<app-pink-back-btn style="background-color: #fff;" (navigateBackEvent)="closeDialog()"></app-pink-back-btn>

<section class="activity-section">
  <div class="img-wrapper">
    <img class="campaign-img" src="assets/images/summer-wheel/string.png" alt="Kampagne">
  </div>

  <!-- <img class="sunny-img" src="assets/images/summer-wheel/sun.png" alt="Kampagne"> -->

  <h1> {{ activity.name | translate }}</h1>

  <app-product-img style="display:flex; justify-content: center; object-fit: cover; min-height: 200px; width: 100%;"
    [url]="activity.imageStr"></app-product-img>

  <!-- Product description -->
  @if (activity.description) {
    <p style="display:flex; justify-content: center; flex-wrap: wrap; margin-bottom: 24px; line-height: 1.5; font-size: var(--adult-body);"
      [innerHTML]="activity.description"></p>
  }

  <!-- Materials -->
  @if (activity.materials && activity.materials.length > 0) {
    <div mat-subheader><strong>{{ 'product-activity.you-will-need' | translate }}</strong></div>

    <ul>
      @for (material of activity.materials; track material) {
        <li class="list-styling" [innerHTML]="material"></li>
      }
    </ul>
    <br>
  }

  <!-- Steps -->
  @if (activity.steps && activity.steps.length > 0) {
    <div>
      @for (step of activity.steps; track step) {
        <p style="font-size: var(--adult-body); line-height: 1.5;" [innerHTML]="step"></p>
      }
    </div>
  }

  <!-- Download -->
  @if (activity.fileStr) {
    <a [href]="activity.fileStr" target="_blank" rel="noopener noreferrer">
      {{ activity.fileStr | fileName }}
    </a>
  }

  <!-- Tip -->
  @if (activity.tip) {
    <section class="tip-box">
      <p
        style="margin: 0; padding: 0; padding-top: 10px; color: #023D5B; font-size: var(--adult-body); font-weight: bold; line-height: 1.5;">
        {{ 'product-activity.tip' | translate
        }}</p>
      <p style="color: #023D5B; font-size: var(--adult-body); line-height: 1.5;">{{ activity.tip }}</p>
    </section>
  }
</section>