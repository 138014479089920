<!-- Background image -->
<div class="bg">
    <app-pink-back-btn mat-dialog-close></app-pink-back-btn>
    <h1>Info</h1>

    <section class="info-card">
        <h2>Hvad går sommerhjulet ud på?</h2>

        <h3>Inspiration</h3>

        <p>Sommerhjulet er et aktivitets hjul der kan spinnes en gang hver dag.
            <br><br>
            Hjulets aktiviteter er taget fra kataloget og er en sjov incitament til at finde på en aktivitet netop i
            dag. Hvis man kan bruge noget spontant sjov aktivitet for familien.
            <br><br>
            Indholdet i sommerhjulet er både fra aktiviteter i inspiration & ideér og der kan også forekomme nye
            aktiviteter som kun fås i sommerhjulet.
        </p>

        <div class="circle">
            <label class="obs-styling">OBS!</label>
        </div>

        <h3>Kun forslag</h3>
        <ul>
            <li>Hjulet skaber forslag til aktiviteter</li>
            <li>Alle økonomiske omkostninger i forbindelse med aktiviteten er afholdt selv af familien. FamilyTricks
                giver ikke økonomisk tilskud vi tilbyder kun forslag til aktiviteter. </li>
        </ul>

    </section>

</div>