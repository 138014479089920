<!-- Background image -->
<div class="bg">

  <app-pink-back-btn (navigateBackEvent)="closeDialog()"></app-pink-back-btn>

  @if (wonActivitiesError() || wheelsError() || catalogActivitiesError()) {
    <app-error-img></app-error-img>
  }

  @if (selectedWheel(); as selectedWheel) {
    <div>
      <h1 class="title-text">{{ 'summer-wheel.title' | translate }}</h1>
      <h3 class="body-text">{{ 'summer-wheel.subtitle' | translate }}</h3>
      <!-- Wheel of summer -->
      <app-wheel-of-summer [activities]="selectedWheel.activities" [canSpin]="!todaysActivity()"
      (wheelSpunEvent)="onWheelSpun($event)" (rotateEvent)="listenForWheelRotation()"></app-wheel-of-summer>
      <section class="figure-section">
        <img class="figure-a" [src]='randomImage'>
      </section>
      <section style="margin: 1rem 24px 4rem; display: flex; flex-direction: column; gap: 1rem;">
        @if (todaysActivity(); as todaysActivity) {
          <p style="color: #f38f41; font-size: var(--adult-h2); font-weight: bold; margin: 0;"> {{ 'summer-wheel.daily-activity' |
          translate }}</p>
          <button class="won-activity-btn" (click)="navigateDailyActivity()">
            <div class="img-wrapper">
              <app-product-img
                style="display:flex; justify-content: center; object-fit: cover; min-height: 105px; width: 100%; border-radius: 10px"
              [url]="todaysActivity.activity.imageStr"></app-product-img>
            </div>
            <div class="arrow-wrapper">
              <fa-icon [icon]="faChevronRight" style="color: #fe019a; font-size: 40px;"></fa-icon>
            </div>
          </button>
        }
        <button class="won-activities-btn" (click)="navigateEarlierActivities()">
          <label style="color: #fff; font-size: var(--adult-btn-text); font-weight: bold; ">{{ 'summer-wheel.earlier-won' | translate
          }}</label>
        </button>
      </section>
      <img class="info-img" src="assets/images/summer-wheel/pink-info.png" (click)="openInfoIcon()">
      <div style=" padding-left:24px; padding-right:24px;">
      </div>
    </div>
  }
</div>